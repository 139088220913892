import axios from "axios";

import store from "../store/index";

import assetsAndLiabilitiesSpreadsheetService from "./assets-liabilities-spreadsheet";
import environmentHelpers from "./environment-helpers";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.replace(/data:.*;base64,/gm, ""));
    reader.onerror = (error) => reject(error);
  });

async function submit() {
  const formData = new FormData();

  // Personal Details
  formData.append("firstName", store.state.firstName);
  formData.append("middleNames", store.state.middleNames);
  formData.append("lastName", store.state.lastName);
  formData.append("identityType", store.state.identificationType);
  formData.append("identityNumber", store.state.identityNumber);
  formData.append("incomeTaxNumber", store.state.incomeTaxNumber);
  formData.append("cellphoneNumber", store.state.cellphoneNumber);
  formData.append("emailAddress", store.state.emailAddress);

  for (let i = 0; i < store.state.identityDocuments.length; i++) {
    formData.append(
      `identityDocument[${i}]`,
      JSON.stringify({
        name: store.state.identityDocuments[i].name,
        contents: await toBase64(store.state.identityDocuments[i]),
      })
    );
  }

  // Foreign Investment Details
  formData.append("investmentAmount", store.state.investmentAmount);
  formData.append("investmentType", store.state.investmentType);
  formData.append("investmentCountry", store.state.investmentCountry);
  formData.append("beneficiaryOfTrust", store.state.beneficiaryOfTrust),
    formData.append("existingLoanToTrust", store.state.existingLoanToTrust),
    formData.append("shareholding20OrMore", store.state.shareholding20OrMore);

  // Assets and liabilities spreadsheet
  if (store.state.assetsAndLiabilities.selectedMethod === "upload") {
    const files = store.state.assetsAndLiabilities.spreadsheetFiles;

    for (let i = 0; i < files.length; i++) {
      formData.append(
        `assetsLiabilitiesSpreadsheet[${i}]`,
        JSON.stringify({
          name: files[i].name,
          contents: await toBase64(files[i]),
        })
      );
    }
  } else if (store.state.assetsAndLiabilities.selectedMethod === "online") {
    const file = assetsAndLiabilitiesSpreadsheetService.generateAssetsLiabilitiesSpreadsheet();
    formData.append(
      "assetsLiabilitiesSpreadsheet[0]",
      await JSON.stringify({
        name: "assetsLiabilitiesSpreadsheet.xlsx",
        contents: await toBase64(file),
      })
    );
  }

  // Source of funds
  formData.append("sourceOfFunds", JSON.stringify(store.state.sourceOfFunds));

  // Source of funds files
  for (const source of store.state.sourceOfFunds) {
    let fileCounter = 0;
    for (const description of Object.keys(store.state.sourceOfFundFiles[source])) {
      for (const file of store.state.sourceOfFundFiles[source][description]) {
        const formDataPrefix = "sourceOfFunds." + source + ".files[" + fileCounter + "]";
        formData.append(
          formDataPrefix + ".file",
          JSON.stringify({
            name: file.name,
            contents: await toBase64(file),
          })
        );
        formData.append(formDataPrefix + ".description", description);

        fileCounter++;
      }
    }
  }

  try {
    if (environmentHelpers.isTest()) {
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
    }

    const responseData = (await axios.post("/TaxClearance.ashx", formData)).data;

    if (responseData.statusCode !== 200) {
      return responseData.errors;
    }
  } catch (e) {
    return [
      "An error occurred while trying to submit your application. Please make sure you are connected to the internet and try again. If that does not work you can contact Mercury FX South Africa on +27 10 329 0470",
    ];
  }

  return null;
}

export default {
  submit,
};
